<template>
  <div v-loading="loading" class="layout">
    <div
      class="border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between"
    >
      <h3 class="text-lg font-medium leading-6 text-gray-900">Organizations</h3>
      <div class="flex">
        <div class="mt-3 sm:mt-0 sm:ml-4 sm:mr-4">
          <label for="search_organizations" class="sr-only">Search</label>
          <div class="flex rounded-md shadow-sm">
            <div class="relative flex-grow focus-within:z-10">
              <div
                class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
              >
                <SearchIcon class="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                v-model="search"
                name="search_organizations"
                id="search_organizations"
                class="search hidden w-full rounded-none rounded-l-md rounded-r-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:block sm:text-sm"
                placeholder="Search"
              />
            </div>
          </div>
        </div>
        <button
          type="button"
          @click="drawer.create = true"
          class="order-0 focus:outline-none inline-flex items-center rounded-md border border-transparent bg-purple-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:ring-purple-500 sm:order-1 sm:ml-3"
        >
          Create
        </button>
      </div>
    </div>

    <div class="flex flex-col" v-if="organisations.length > 0">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div
            class="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Address
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Region
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Manager
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Members
                  </th>
                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr
                  v-for="organisation in organisations"
                  :key="organisation.Id"
                >
                  <td class="whitespace-nowrap px-6 py-4">
                    <div class="flex items-center">
                      <div
                        class="h-10 w-10 flex-shrink-0"
                        v-if="organisation.LogoImage !== null"
                      >
                        <img
                          class="h-10 w-10 rounded-full"
                          :src="`data:image/png;base64, ${organisation.LogoImage}`"
                          alt=""
                        />
                      </div>
                      <div class="ml-4">
                        <div class="text-sm font-medium text-gray-900">
                          {{ organisation.Name }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-left">
                    <div class="text-sm text-gray-900">
                      {{ organisation.AddressLine1 }}
                    </div>
                    <div class="text-sm text-gray-500">
                      {{ organisation.AddressLine2 }}
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-left">
                    <div class="text-sm text-gray-900">
                      {{ organisation.City }} | {{ organisation.ZipCode }}
                    </div>
                    <div class="text-sm text-gray-500">
                      {{ organisation.Region }} | {{ organisation.State }}
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-left">
                    <span
                      v-if="organisation.OrganizationManager !== null"
                      class="inline-flex items-center rounded-full bg-indigo-100 px-2.5 py-0.5 text-xs font-medium text-indigo-800"
                    >
                      {{
                        organisation.OrganizationManager !== null
                          ? organisation.OrganizationManager.Parent.FullName
                          : null
                      }}
                    </span>
                  </td>
                  <td class="px-6 py-3 text-sm font-medium text-gray-500">
                    <div class="flex items-center space-x-2">
                      <div class="flex flex-shrink-0 -space-x-1">
                        <span
                          v-for="member in organisation.Members"
                          :key="member.FullName"
                        >
                          <img
                            v-tippy="{
                              content: `${member.FullName}`,
                              animation: 'scale',
                              animateFill: false,
                              theme: 'tomato',
                            }"
                            v-if="member.Picture !== null"
                            class="h-6 w-6 max-w-none rounded-full ring-2 ring-white"
                            :src="`data:image/png;base64, ${member.Picture}`"
                            :alt="member.FullName"
                          />
                        </span>
                      </div>
                      <!-- <span
                        v-if="
                          organisation.totalMembers > organisation.Members.length
                        "
                        class="flex-shrink-0 text-xs leading-5 font-medium"
                        >+{{
                          organisation.totalMembers - organisation.Members.length
                        }}</span
                      > -->
                    </div>
                  </td>
                  <td
                    class="whitespace-nowrap px-6 py-4 text-right text-sm font-medium"
                  >
                    <button
                      type="button"
                      v-tippy="{
                        content: 'Edit',
                        animation: 'scale',
                        animateFill: false,
                        theme: 'tomato',
                      }"
                      @click="handleEdit(organisation)"
                      class="focus:outline-none inline-flex items-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 text-purple-400 shadow-sm hover:text-purple-800 focus:ring-purple-500"
                    >
                      <PencilIcon class="h-5 w-5 flex-shrink-0 text-gray-400" />
                    </button>
                    <button
                      type="button"
                      v-tippy="{
                        content: 'Manage',
                        animation: 'scale',
                        animateFill: false,
                        theme: 'tomato',
                      }"
                      @click="handleManage(organisation)"
                      class="focus:outline-none inline-flex items-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 text-purple-400 shadow-sm hover:text-purple-800 focus:ring-purple-500"
                    >
                      <CogIcon class="h-5 w-5 flex-shrink-0 text-purple-400" />
                    </button>
                    <button
                      type="button"
                      v-tippy="{
                        content: 'Delete',
                        animation: 'scale',
                        animateFill: false,
                        theme: 'tomato',
                      }"
                      @click="handleDeleteOrg(organisation.Id)"
                      class="focus:outline-none inline-flex items-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 text-red-400 shadow-sm hover:text-red-800 focus:ring-red-500"
                    >
                      <TrashIcon class="h-5 w-5 flex-shrink-0 text-red-400" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>

            <pagination :pagination="pagination" @changePage="onChangePage" />
          </div>
        </div>
      </div>
    </div>
    <el-empty
      v-if="organisations.length === 0"
      description="Looking for organisations"
    ></el-empty>
  </div>
  <el-drawer
    title="Create Organization"
    v-model="drawer.create"
    :direction="direction"
  >
    <el-form class="p-8" ref="createForm" :model="form">
      <el-form-item label="Name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="Address 1">
        <el-input v-model="form.addressLine1"></el-input>
      </el-form-item>
      <el-form-item label="Address 2">
        <el-input v-model="form.addressLine2"></el-input>
      </el-form-item>
      <el-form-item label="City">
        <el-input v-model="form.city"></el-input>
      </el-form-item>
      <el-form-item label="State">
        <el-input v-model="form.state"></el-input>
      </el-form-item>
      <el-form-item label="Zip Code">
        <el-input v-model="form.zipCode"></el-input>
      </el-form-item>
      <el-form-item label="Region">
        <el-input v-model="form.region"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button class="w-full" type="primary" @click="onSubmit">
          Create
        </el-button>
      </el-form-item>
    </el-form>
  </el-drawer>
  <el-drawer
    title="Organization Management"
    v-model="drawer.manage"
    :direction="direction"
  >
    <div class="drawer--header">
      <p class="flex justify-center justify-items-center">
        <img
          v-if="selectOrganization.value.LogoImage !== null"
          class="mr-4 block h-10 w-auto"
          :src="`data:image/png;base64, ${selectOrganization.value.LogoImage}`"
          alt="Workflow"
        />
        <strong>{{ selectOrganization.value.Name }}</strong>
      </p>
    </div>
    <div class="drawer--body">
      <!-- This example requires Tailwind CSS v2.0+ -->
      <div class="rounded-md bg-blue-50 p-4">
        <div class="flex">
          <div class="flex-shrink-0">
            <!-- Heroicon name: solid/information-circle -->
            <svg
              class="h-5 w-5 text-blue-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <div class="ml-3 flex-1 md:flex md:justify-between">
            <p class="text-sm text-blue-700">
              Find and select person to be part of organisation or Manager.
              Click on Link button on the right to approve that person.
            </p>
          </div>
        </div>
      </div>

      <div class="section section--managers">
        <div class="title">
          <p>Manager</p>
        </div>
        <div class="info">
          <ul class="divide-y divide-gray-200">
            <li
              class="flex py-4"
              v-if="selectOrganization.value.OrganizationManager !== null"
            >
              <img
                v-if="
                  selectOrganization.value.OrganizationManager.Parent
                    .Picture !== null
                "
                class="h-10 w-10 rounded-full"
                :src="`data:image/png;base64, ${selectOrganization.value.OrganizationManager.Parent.Picture}`"
                alt=""
              />
              <svg
                v-else
                width="38"
                height="38"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  color="text-gray-400"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <div class="ml-3">
                <p class="text-sm font-medium text-gray-900">
                  {{
                    selectOrganization.value.OrganizationManager.Parent.FullName
                  }}
                </p>
                <p class="text-sm text-gray-500">
                  {{
                    selectOrganization.value.OrganizationManager.Parent.Username
                  }}
                </p>
              </div>
              <div class="ml-auto p-4">
                <button class="text-gray-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    width="18"
                    height="18"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                    />
                  </svg>
                </button>
              </div>
            </li>
            <li v-else>
              <div class="rounded-md bg-yellow-50 p-4">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <!-- Heroicon name: solid/exclamation -->
                    <svg
                      class="h-5 w-5 text-yellow-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                  <div class="ml-3">
                    <h3 class="text-sm font-medium text-yellow-800">
                      Attention needed
                    </h3>
                    <div class="mt-2 text-sm text-yellow-700">
                      <p>
                        This organization is missing a manager. Please select a
                        user and assign manager role.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="space-y-1 sm:mt-4">
                <p id="add_team_members_helper" class="sr-only">
                  Search by email address
                </p>
                <div class="flex">
                  <el-autocomplete
                    class="w-full"
                    v-model="managerInvite"
                    :fetch-suggestions="querySearchManager"
                    placeholder="Please search"
                    :trigger-on-focus="false"
                    @select="handleSelectManager"
                  >
                  </el-autocomplete>
                  <span class="ml-3">
                    <button
                      type="button"
                      @click="handleInviteManager"
                      class="focus:outline-none focus:ring-light-blue-500 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
                    >
                      <svg
                        class="-ml-2 mr-1 h-5 w-5 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      <span>Add</span>
                    </button>
                  </span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="section section--users">
        <div class="title">
          <p>Organization Users:</p>
        </div>
        <div class="flex">
          <el-select v-model="role" clearable placeholder="Select Role">
            <el-option
              v-for="item in roles"
              :key="item.Id"
              :label="item.Name"
              :value="item.Id"
            >
            </el-option>
          </el-select>
          <el-autocomplete
            class="w-full"
            v-model="listOfInvitees"
            :fetch-suggestions="querySearchUser"
            placeholder="Please search"
            :trigger-on-focus="false"
            @select="handleSelectUser"
          >
          </el-autocomplete>
          <span class="ml-3">
            <button
              type="button"
              @click="handleUserInvite"
              class="focus:outline-none focus:ring-light-blue-500 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
            >
              <svg
                class="-ml-2 mr-1 h-5 w-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                  clip-rule="evenodd"
                />
              </svg>
              <span>Add</span>
            </button>
          </span>
        </div>
        <ul class="divide-y divide-gray-200">
          <li
            v-for="user in selectOrganization.orgUsers"
            :key="user.Id"
            class="flex justify-between py-4"
          >
            <div class="flex">
              <img
                v-if="user.Parent.Picture"
                class="h-10 w-10 rounded-full"
                :src="`data:image/png;base64, ${user.Parent.Picture}`"
                alt=""
              />
              <svg
                v-else
                width="18"
                height="18"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <div class="ml-3">
                <p class="text-sm font-medium text-gray-900">
                  {{ user.Parent.FullName }}
                  <span
                    v-if="user.OrganizationRoles.length > 0"
                    class="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5"
                  >
                    <div
                      class="absolute flex flex-shrink-0 items-center justify-center"
                    >
                      <span
                        class="h-1.5 w-1.5 rounded-full bg-rose-500"
                        aria-hidden="true"
                      ></span>
                    </div>
                    <div class="ml-3.5 text-sm font-medium text-gray-900">
                      {{ user.OrganizationRoles[0].Name }}
                    </div>
                  </span>
                </p>

                <p class="text-sm text-gray-500">{{ user.Parent.Username }}</p>
              </div>
            </div>

            <div class="p-4">
              <button class="text-gray-500" @click="removeUserFromOrg(user.Id)">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  width="18"
                  height="18"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
              </button>
            </div>
          </li>
          <li v-if="selectOrganization.orgUsers.length === 0">
            <el-empty
              description="No users found, just this empty box"
            ></el-empty>
          </li>
        </ul>
      </div>
    </div>
    <div class="drawer--footer"></div>
  </el-drawer>

  <el-dialog
    title="Delete Organization"
    custom-class="dialog-custom"
    v-model="dialog.show"
    destroy-on-close
    width="30%"
  >
    <span
      >Once you delete the organization, you will lose all data associated with
      it.</span
    >
    <template #footer>
      <span class="dialog-footer">
        <div class="mt-5">
          <button
            type="button"
            @click="onDeleteOrganization"
            class="focus:outline-none inline-flex items-center justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 font-medium text-red-700 hover:bg-red-200 focus:ring-red-500 sm:text-sm"
          >
            Delete
          </button>
        </div>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { defineComponent } from "vue";

import {
  TrashIcon,
  PencilIcon,
  CogIcon,
  SearchIcon,
} from "@heroicons/vue/outline";
import { directive } from "vue-tippy";

import Pagination from "@/common/components/pagination";
import _ from "lodash";
import API from "@/api";

import "tippy.js/animations/scale.css";

export default defineComponent({
  components: {
    Pagination,
    TrashIcon,
    PencilIcon,
    CogIcon,
    SearchIcon,
  },
  directives: {
    tippy: directive,
  },
  data() {
    return {
      loading: false,
      search: "",
      drawer: {
        create: false,
        manage: false,
      },
      direction: "rtl",
      pagination: {
        page: 1,
        pageSize: 10,
        totalResults: null,
      },
      organisations: [],
      selectOrganization: {
        value: {},
        orgUsers: [],
        orgManager: null,
        invitee: null,
      },
      managerInvite: "",
      listOfInvitees: [],
      systemUsers: [],
      form: {
        name: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zipCode: "",
        region: "",
      },
      dialog: {
        show: false,
        organisationId: null,
      },
      role: "",
      roles: [],
    };
  },
  created() {
    this.fetchOrganizations();

    this.debouncedGetAnswer = _.debounce(this.getAnswer, 1000);
  },
  methods: {
    async getAnswer() {
      let results = await API.Admin.Organisations.search(
        this.search,
        this.pagination.page,
        this.pagination.pageSize
      );
      this.organisations = results.data.List;

      this.pagination.page = results.data.Page;
      this.pagination.pageSize = results.data.PageSize;
      this.pagination.totalResults = results.data.TotalResults;
    },
    async fetchOrganizations() {
      this.loading = true;
      try {
        let results = await API.Admin.Organisations.get(
          this.pagination.page,
          this.pagination.pageSize
        );
        this.organisations = results.data.List;

        this.pagination.page = results.data.Page;
        this.pagination.pageSize = results.data.PageSize;
        this.pagination.totalResults = results.data.TotalResults;

        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log({ error });
      }
    },
    handleEdit(item) {
      console.log({ item });
    },

    onChangePage(page) {
      this.pagination.page = page;

      this.fetchOrganizations();
    },

    async handleManage(item) {
      let orgUsers = await API.Admin.Organisations.getUsers(item.Id);
      this.selectOrganization.orgUsers = orgUsers.data.List;
      this.selectOrganization.value = item;
      this.drawer.manage = true;
    },

    async querySearchUser(queryString, cb) {
      let query = await API.Admin.Users.search(queryString);
      const users = [];
      query.data.List.forEach((user) => {
        users.push({
          value: user.FullName,
          item: user,
        });

        // call callback function to return suggestions
        cb(users);
      });
    },

    async querySearchManager(queryString, cb) {
      let query = await API.Admin.Users.search(queryString);
      const users = [];
      query.data.List.forEach((user) => {
        users.push({
          value: user.FullName,
          item: user,
        });

        // call callback function to return suggestions
        cb(users);
      });
    },

    async getOrgRoles(orgId) {
      let roles = await API.Admin.Organisations.getOrganizationRoles(orgId);
      this.roles = roles.data.List;
    },

    handleSelectUser(val) {
      console.log("v", val);
      this.selectOrganization.invitee = val;
    },

    handleSelectManager(val) {
      this.selectOrganization.orgManager = val;
    },

    handleInviteManager() {
      let data = {
        organizationId: this.selectOrganization.value.Id,
        organizationUserId: this.selectOrganization.orgManager.item.Id,
      };

      API.Admin.Organisations.setAdmin(data)
        .then(() => {
          this.$notify({
            title: "Success",
            message: "Organization Manager successfully added.",
            type: "success",
          });
        })
        .catch(() => {
          this.$notify({
            title: "Warning",
            message: "Problem while adding Organization Manager!",
            type: "warning",
          });
        });
    },

    handleUserInvite() {
      console.log("rr", this.selectOrganization.invitee);
      if (this.selectOrganization.invitee === null) {
        this.$notify.info({
          title: "Info",
          message: "User must be selected to be added to organization.",
        });
        return;
      }
      API.Admin.Users.organizationInvite(
        this.selectOrganization.invitee.item.Username,
        this.selectOrganization.value.Id
      )
        .then((res) => {
          this.$notify({
            title: "Success",
            message: "User added to organization",
            type: "success",
          });
          console.log({ res });
          this.selectOrganization.orgUsers.push(res.data.item);
        })
        .catch(() => {
          this.$notify({
            title: "Warning",
            message: "Problem while adding user to organization.",
            type: "warning",
          });
        });
    },

    onSubmit() {
      API.Admin.Organisations.save(this.form).then(() => {
        this.fetchOrganizations();
      });
    },

    removeUserFromOrg(userId) {
      console.log({ userId });
      API.Admin.Users.removeFromOrg(userId).then(() => {
        this.$notify({
          title: "Success",
          message: "User removed from Organization",
          type: "success",
        });
      });
    },

    handleDeleteOrg(id) {
      this.dialog.show = true;
      this.dialog.organisationId = id;
    },

    onDeleteOrganization() {
      API.Admin.Organisations.delete(this.dialog.organisationId).then(() => {
        this.dialog.show = false;

        this.$notify({
          title: "Success",
          message: "Organization deleted!",
          type: "success",
        });
        this.fetchOrganizations();
      });
    },
  },
  watch: {
    search: function (val) {
      if (val.length > 0) {
        this.debouncedGetAnswer();
      }
    },
  },
});
</script>

<style scoped>
.layout {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  padding: 24px;
  height: 100%;
  background-color: #fff;
  border-radius: 12px;
}
.p-8 {
  padding: 32px;
}
</style>
